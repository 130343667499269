import { useContext } from "react";
import { Cross, Minimise } from "../components/Icons";
import { useChat } from "../context/chat-context";
import { ModalContext } from "../hooks/useModal";

interface CollapseControlsProps {
  handleModalClose: () => void;
}

const CollapseControls = ({ handleModalClose }: CollapseControlsProps) => {
  const { reset, questions } = useChat();
  const context = useContext(ModalContext);

  const handleClose = () => {
    reset();
    handleModalClose();
    context?.setInterim(false);
  };

  const handleMinimise = () => {
    handleModalClose();
    context?.setInterim(false);
  };

  if (!questions.length) {
    return null;
  }

  return (
    <div className="flex top-4 right-10 absolute z-50">
      <button
        aria-label="Minify"
        className="pointer-events-auto"
        onClick={handleMinimise}
      >
        <Minimise />
      </button>
      <button
        aria-label="Close"
        onClick={handleClose}
        className="pointer-events-auto focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-white cursor-pointer ml-2"
      >
        <Cross crossColour="fill-icons-crosslight" />
      </button>
    </div>
  );
};

export default CollapseControls;
